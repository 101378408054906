import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Option } from 'src/app/shared/models/option';
import { AreaMedia } from '../../models/redshift/area-media';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {Insertion} from '../../models/insertion/insertion';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AreaMediaService {
    constructor(private http: HttpClient) { }

    areas() {
        const url = `${environment.url}/redshift/areas`;
        return this.http.get<Array<AreaMedia>>(url);
    }

    medias(area: string) {
        const url = `${environment.url}/redshift/areas/${area}`;
        return this.http.get<Array<AreaMedia>>(url);
    }

    change(insertion: Insertion, midia: string, adServer = null) {
        const url = `${environment.urlMicro}/redshift/change-media/area/${insertion.area}/media/${midia}${(adServer ? '/' + adServer : '')}`;
        return this.http.post<boolean>(url, insertion);
    }

    changeArea(id: number, area: string) {
        const url = `${environment.url}/redshift/change-area/${id}`;
        return this.http.post<boolean>(url,  {'area': area});
    }

    toOptions(data: Array<AreaMedia>): Array<Option> {
        const options: Array<Option> = [];

        for (const i in data) {
            if (data[i].area) {
                options.push({
                    label: data[i].area,
                    value: data[i].area
                });
            } else {
                options.push({
                    label: data[i].midia,
                    value: data[i].midia
                });
            }
        }

        return options;
    }

    verifySiteId(adServer: string, midia: string, siteId: string) {
        const url = `${environment.urlMicro}/insertions/sts_mmm_area_midia/verify_site_id`;
        return this.http.post(url, { ad_server: adServer, midia: midia, site_id: siteId});
    }

}
